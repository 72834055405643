import React from "react";
import styles from "./Bio.module.css";
import image from "../images/mypic.jpeg";
function Bio(props) {
  return (
    <div
      className={`
      ${styles.Bio}
      ${props.className || ""}
    `}
    >
      <img src={image} alt="Me" />
      <p>
        Personal Blog
        <br />I explain with words and code.
      </p>
    </div>
  );
}

export default Bio;
