import React from "react";
import { Link } from "react-navi";
import ArticleMeta from "./ArticleMeta";
import styles from "./ArticleSummary.module.css";

function ArticleSummary({ blogRoot, route }) {
  console.log("blog", route);
  return (
    <article className={styles.ArticleSummary}>
      <div className={styles.ArticleSpace}>
        <h2>
          <Link href={route.url.href}>{route.title}</Link>
        </h2>
        <ArticleMeta blogRoot={blogRoot} meta={route.data} />
        <p>{route.data.spoiler}</p>
      </div>
      <div>
        <img
          src={route.data.image}
          alt="Blog Post Image"
          className={styles.ArticleImage}
        />
      </div>
    </article>
  );
}

export default ArticleSummary;
