export default {
  // The blog's title as it appears in the layout header, and in the document
  // <title> tag.
  title: "Klendi Gocci",

  author: "Klendi Gocci",
  description: "My Personal blog",

  // The number of posts to a page on the site index.
  indexPageSize: 10,
}
